import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';

class About extends React.Component {
  public componentDidMount(){
    document.title = "About Wemint"
  }
  public render() {
    return (
      <div className="About">
         <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
           <h1 className="catchphrase"><FormattedMessage id="about.lead" defaultMessage="About us"/> <span className="mint"></span></h1>
<br/>      
              <p className="lead">
                {/*<!--<strong>Who we are:</strong><br/>-->*/}
                
                We are a team of IT consultants based in France<br/>
                and we specialize in creating robust & well-thought<br/>
                infrastructures for businesses.<br/><br/>
                
                We strive to empower your company and provide you<br/>
                with the best solution, tailored to your needs.<br/><br/>

                With our expertise, rest assured that you will always<br/>
                have your website, emails and more, running at all times.<br/><br/>

                <Link to="contact">Get in touch</Link> with us if you wish to know more!
              </p>
          
        </div>
      </div>
    );
  }
}

export default About;

