import * as React from 'react';

class Terms extends React.Component {
  public componentDidMount(){
    document.title = "Wemint & its terms."
  }
  public render() {
    return (
      <div className="Terms">
        <div className="container">
        <br/>
          <p>
          <h2 className="thin-4">Terms of Use</h2><br/>

            By accessing or using the Wemint website, you agree to be bound by the following Terms of Use, that affect your legal rights and obligations:<br/><br/>

            - You must not violate your local laws nor the European laws.<br/>
            - You must not cyberattack Wemint nor any product directly connected with it (examples include DoS, DDoS, SPAM and any other).<br/>
            - You must not use Wemint as a carrier of any software or content that may be harmful (virus, phishing pages, etc.).<br/>
            - You must use Wemint services reasonably and moderately, so as not to not put other users at a disadvantage.<br/>
            - You must not host any TOR relay, or any other network relay.<br/>
            - You must not transmit any immoral content.<br/>
            - You must not use any crypto-currency mining software.<br/><br/>

            In case of any abuse of the Terms of Use, Wemint reserves the right to restrict or immediately terminate the buyer’s subscription and overall access to Wemint’s services without a refund, and alert relevant authorities if necessary.<br/><br/>

            The buyer can terminate his/her subscription up to 14 days after making the purchase. In this case, Wemint will provide a full refund, while subtracting only a fraction of the amount paid corresponding to the number of days where the product was in the ownership of the buyer.<br/><br/>
          <br/><h2 className="thin-4">Privacy Policy</h2><br/>

            Wemint (“us”, “we” or “our”) operates <a href="https://www.wemint.net">Wemint.net</a>. Privacy is a freedom that we strongly care about, which is why Wemint affirms its commitment to this Policy and:<br/><br/>
            - will only use cookies for technical purposes (avoid the necessity to log in with with each visit, remember the user’s preferences, etc.),<br/>
            - will only use the user’s IP address for diagnostics purposes,<br/>
            - will not disclose its database to third parties,<br/>
            - will not resort to using external audience measurement tools, such as Analytics,<br/>
            - will work as hard as possible in order to provide a service that respects the privacy of our users.<br/><br/>

            We reserve the right to access your account in case of a technical or legal issue, in order to apply appropriate modifications.<br/><br/>
          <br/><h2 className="thin-4 wemint">Environment Policy</h2><br/>

            In order to reconcile our contemporary needs with the environment, we strive to:<br/>
            - choose quality partners who care about environmental issues,<br/>
            - minimize the waste of resources as much as possible,<br/>
            - select equipment that is reliable, efficient and environmentally friendly.<br/>
          </p>
        </div>
      </div>
    );
  }
}

export default Terms;

