import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import messages from './Messages'

interface SetLocaleProps extends RouteComponentProps {
    ForceLocaleHandler: (locale: string, path: string) => void
}

class SetLocale extends React.Component<SetLocaleProps> {
    componentDidMount() {
        let lang = this.props.location.pathname.substring(1)
        const path = lang.substring(lang.indexOf("/") + 1)
        lang = lang.substring(0, lang.indexOf("/"))
        
        if(!Object.keys(messages).includes(lang)) {
            lang = "en-US"
        }

        this.props.ForceLocaleHandler(lang, path)
    }

    render () {
        return null
    }
}

export default withRouter(SetLocale);