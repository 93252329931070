import * as React from 'react';

import { FormattedMessage } from 'react-intl';

class Legal extends React.Component {
  public componentDidMount(){
    document.title = "Wemint"
  }
  public render() {
    return (
      <div className="Legal">
         <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
           <h1 className="catchphrase"><FormattedMessage id="legal.lead" defaultMessage="Legal information"/></h1>
<br/>      
              <p className="lead">
                {/*<!--<strong>Who we are:</strong><br/>-->*/}
                Wemint (“us”, “we” or “our”) operates <a href="#">Wemint.net</a><br/>
                Wemint is represented by its current director: Gabriel Ouiran.<br/><br/>

                <strong><abbr title="Drop us an email instead? hello@wemint.net">P.O. Box:</abbr></strong><br/>
                8 Allée des Palmes<br/>
                89000 Auxerre<br/>
                France<br/><br/>

                <strong><abbr title="French commerce number">SIRET:</abbr></strong><br/>
                81045630100011<br/><br/>

                <strong>Banking info:</strong><br/>
                IBAN:<br/>
                FR76 4061 8803 3400 0404 1803 775<br/>
                BIC:<br/>
                BOUS FRPP XXX<br/><br/>


              </p>
          
        </div>
      </div>
    );
  }
}

export default Legal;

