import * as React from 'react';

import { FormattedMessage } from 'react-intl';

class Contact extends React.Component {
  public componentDidMount(){
    document.title = "Contacting Wemint"
  }
  public render() {
    return (
      <div className="Contact">
         <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
           <h1 className="catchphrase"><FormattedMessage id="contact.lead" defaultMessage="Contact us"/> <span className="mint"></span></h1>
<br/>      
              <p className="lead">
                {/*<!--<strong>Who we are:</strong><br/>-->*/}
                
                <FormattedMessage 
                  id="contact.email.lead"
                  defaultMessage="Reach out to us by email:"/><br/>
              
                <a href="">
                <FormattedMessage 
                  id="contact.email.line" 
                  defaultMessage="contact@wemint.net"/></a><br/><br/>

                {/*<FormattedMessage 
                  id="contact.phone.lead" 
                  defaultMessage="Reach us by phone:"/><br/>
              
                <a href="">
                <FormattedMessage 
                  id="contact.phone.line" 
                defaultMessage="+33 95 010 2030 (fake)"/></a><br/><br/>*/}

                We are available from Monday to Friday, 8am to 6pm.<br/>
                We will call you back for free.<br/><br/>
                Outside these hours, we will do our best and get back<br/>
                to you as soon as possible.<br/>

              </p>
          
        </div>
      </div>
    );
  }
}

export default Contact;

