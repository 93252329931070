

const messages = {
  'en-US': {
    'langPath': 'en',
    'lang': 'EN',
    'header.home': 'Home',
    'header.services': 'Services',
    'header.contact': 'Contact',
    'footer.status': 'status',
    'footer.abuse': 'abuse',
    'footer.terms': 'terms & conditions',
    'footer.legal': 'legal',

    
    'homepage.slogan': 'Your digital infrastructure manager.',
    'homepage.subline1': 'We take care of your IT infrastructure',
    'homepage.subline2': 'with simplicity and performance in mind.',
    'homepage.contact-button': 'Get in touch',

    'contact.lead': 'Contact us',
    'contact.slogan': 'For any inquiries, questions or more, don\'t hesitate to get in touch.',
    'contact.contact-info': 'Contact information',
    'contact.email.lead': 'Reach out to us by email:',
    'contact.email.line': 'contact@wemint.net ',
    'contact.email.line2': '(mon-fri 8am-6pm)',
    'contact.phone.lead': 'Reach us by phone:',
    'contact.phone.line': '+33 95 010 2030',
    'contact.phone.line2': '(mon-fri 8am-6pm)',
  },
  'pl-PL': {
    'langPath': 'pl',
    'lang': 'PL',
    'header.home': 'Strona glowna',
    'header.services': 'Uslugi',
    'header.contact': 'Kontakt',
    'footer.status': 'status',
    'footer.abuse': 'abuse',
    'footer.terms': 'regulamin',
    'footer.legal': 'legal',

    'homepage.slogan': 'polski',
    'homepage.subline1': 'We take care of your IT infrastructure,',
    'homepage.subline2': 'with simplicity and performance in mind.',
    'homepage.contact-button': 'Get in touch',

    'contact.lead': 'About',
    /*'contact.contact-info': '',
    'contact.email.lead': '',
    'contact.email.line': '',
    'contact.email.line2': '',
    'contact.phone.lead': '',
    'contact.phone.line': '',
    'contact.phone.line2': '',
    */
  },
  'fr-FR': {
    'langPath': 'fr',
    'lang': 'FR',
    'header.home': 'Accueil',
    'header.services': 'Services',
    'header.contact': 'Contact',
    'footer.status': 'status',
    'footer.abuse': 'abuse',
    'footer.terms': 'cgu',
    'footer.legal': 'mentions légales',

    'homepage.slogan': 'Votre gestionnaire d\'infrastructures numériques.',
    'homepage.subline1': 'We take care of your IT infrastructure,',
    'homepage.subline2': 'with simplicity and performance in mind.',
    'homepage.contact-button': 'Contactez-nous',

    'contact.lead': 'À propos de',
    'contact.contact-info': 'Nous contacter',
    'contact.email.lead': 'Nous écrire un email :',
    'contact.email.line': 'bonjour@wemint.net',
    'contact.email.line2': '(lun-ven, 8h-18h, pour tout renseignement)',
    'contact.phone.lead': 'Nous passer un coup de fil :',
    'contact.phone.line': '+33 95 010 2030 (fake)',
    'contact.phone.line2': '(lun-ven, 8h-18h, nous pouvons vous rappeler)',
  }
};

export default messages;