import * as React from 'react';
import { 
  Link, 
  NavLink, 
  } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { 
  ButtonDropdown, 
  DropdownItem, 
  DropdownMenu, 
  DropdownToggle 
} from 'reactstrap';
import logo from '../assets/logo.svg';

interface IDDState { 
  dropdownOpen: boolean;
}


interface HeaderProps {
  ChangeLocaleHandler: (locale: string) => void
  Locale: string
}

export default class Header extends React.Component<HeaderProps, IDDState> {

/* Dropdown button part */
constructor(props: Readonly<any>) {
  super(props);
  this.toggle = this.toggle.bind(this);
  this.state = {
    dropdownOpen: false,
  };
}


public toggle() {
  this.setState({
    ...this.state,
    dropdownOpen: !this.state.dropdownOpen
  });
}
/* End of Dropdown button part */

  public render() {
    return (
      <div id="header" className="d-flex flex-column flex-md-row align-items-center py-2 px-md-1 container">
        <nav className="my-0 mr-md-auto font-weight-normal">
          <Link to={`/${this.props.Locale}/home`} className="p-2 text-dark"><img className="mb-2" src={logo} alt="" width="160" height="" /></Link>
           {/* <!--<a className="p-2 text-dark" href="/contact">Contact</a>--> */}
          { /* <button onClick={() => { this.props.ChangeLocaleHandler("en-US") }}>EN</button>
               <button onClick={() => { this.props.ChangeLocaleHandler("pl-PL") }}>PL</button>
               <button onClick={() => { this.props.ChangeLocaleHandler("fr-FR") }}>FR</button> */ } {/*
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <Link to="" className="m-2 text-dark"><DropdownToggle tag="a" data-toggle="dropdown" caret={true}>
            <FormattedMessage id="lang" defaultMessage="lang"/>
          </DropdownToggle></Link>
          <DropdownMenu>
            <DropdownItem onClick={() => { this.props.ChangeLocaleHandler("en-US") }}>English</DropdownItem>
            <DropdownItem onClick={() => { this.props.ChangeLocaleHandler("fr-FR") }}>Français</DropdownItem>
            <DropdownItem onClick={() => { this.props.ChangeLocaleHandler("pl-PL") }}>Polski</DropdownItem>
          </DropdownMenu>
               </ButtonDropdown> */ }

        </nav>
        <nav className="my-2 my-md-0 mr-md-3">
          <NavLink activeClassName="active" className="m-2 text-dark font-weight-bold link" to={`/${this.props.Locale}/home`}><FormattedMessage id="header.home" defaultMessage="Home"/></NavLink>
          <NavLink activeClassName="active" className="m-2 text-dark font-weight-bold link" to={`/${this.props.Locale}/about`}><FormattedMessage id="header.about" defaultMessage="About"/></NavLink>
          <NavLink activeClassName="active" className="m-2 text-dark font-weight-bold link" to={`/${this.props.Locale}/contact`}><FormattedMessage id="header.contact" defaultMessage="Contact"/></NavLink>
          {/* <!--<a className="p-2 text-dark" href="/contact">Contact</a>--> */}
        </nav>
      </div>
    )
  }
}