import * as React from 'react';
import { 
  Redirect, 
  Route, 
  Switch, 
  withRouter, 
  RouteComponentProps 
  } from 'react-router-dom';
import { IntlProvider } from "react-intl";

import Header from './components/Header'
import Footer from './components/Footer'
import SetLocale from './components/SetLocale'

import messages from './components/Messages';

import About from './pages/About';
import Contact from './pages/Contact';
import Homepage from './pages/Homepage';
import Legal from './pages/Legal';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms';

import './App.css';

class App extends React.Component<RouteComponentProps> {

  constructor(props: any) {
    super(props);

    this.changeLocale = this.changeLocale.bind(this);
  }

  state = {
    locale: 'en-US'
  };

  changeLocale = (locale: string) => {
    this.props.history.push(this.props.location.pathname.replace(messages[this.state.locale as keyof typeof messages]['langPath'], messages[locale as keyof typeof messages]['langPath']));
    this.setState({locale: locale});
  };

  forceLocale = (locale: string, path: string) => {
    this.props.history.push(`/${messages[locale as keyof typeof messages]['langPath']}/${path}`);
    this.setState({locale: locale});
  }

  render() {
    const locale = this.state.locale
    const localeUrl = messages[locale as keyof typeof messages]['langPath']

    return (
      <IntlProvider locale={locale} messages={messages[locale as keyof typeof messages]}>
        <div id="website" className="App">
          <Header ChangeLocaleHandler={this.changeLocale} Locale={localeUrl} />
          <div id="content">
            <Switch>
              <Redirect exact from={"/"} to={`/${localeUrl}/home`}/>
              <Route    exact path={`/${localeUrl}/home`}     component={Homepage}/>
              <Route    exact path={`/${localeUrl}/contact`}  component={Contact}/>
              <Route    exact path={`/${localeUrl}/about`}    component={About}/>
              <Route    exact path={`/${localeUrl}/legal`}  component={Legal}/>
              <Route    exact path={`/${localeUrl}/terms`}    component={Terms}/>
              <Route          path="/:lang" render={() => <SetLocale ForceLocaleHandler={this.forceLocale}/>} />
            </Switch>
          </div>
          <Footer Locale={localeUrl}/>
        </div>
      </IntlProvider>
    );
  }
}

export default withRouter(App);
