import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface FooterProps {
  Locale: string
}

export default class Footer extends React.Component<FooterProps> {
  public render() {
    return (
        <div id="footer" className="container mt-3 my-md-1 pt-md-4">
          <div className="col-12 col-md">
            <small className="d-block mb-3 text-muted">
              &copy; {new Date().getFullYear()} - Wemint
              <span className="float-right">
                {/* <Link className="text-muted" to="#"><FormattedMessage id="footer.status" defaultMessage="status"/></Link> -&nbsp;
                <Link className="text-muted" to="#"><FormattedMessage id="footer.abuse" defaultMessage="abuse"/></Link> -&nbsp; */ }
                <Link className="text-muted" to={`/${this.props.Locale}/terms`}><FormattedMessage id="footer.terms" defaultMessage="terms & conditions"/></Link> -&nbsp;
                <Link className="text-muted" to={`/${this.props.Locale}/legal`}><FormattedMessage id="footer.legal" defaultMessage="legal"/></Link>
              </span>
            </small>
          </div>
        </div>
    )
  }
}