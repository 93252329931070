import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class Homepage extends React.Component {
  public componentDidMount(){
    document.title = "Wemint"
  }
  public render() {
    return (
      <div className="Homepage">
        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          { /* <h1 className="thin-2 font-weight-lighter">We<span className="mint">mint</span>.</h1>
          <p className="lead"><FormattedMessage id="homepage.slogan" defaultMessage="Your digital infrastructure manager."/></p> */ }
          <h1 className="catchphrase"><FormattedMessage id="homepage.slogan" defaultMessage="Your digital infrastructure manager."/></h1>
          <hr className="mt-5 mb-5"/>
          <p className="lead"><FormattedMessage id="homepage.subline1" defaultMessage="We take care of your IT infrastructure"/></p>
          <p className="lead"><FormattedMessage id="homepage.subline2" defaultMessage="with simplicity and performance in mind."/></p>
          <br/>
          <p>
            <Link to={`contact`} className="btn btn-primary">
              <FormattedMessage id="homepage.contact-button" defaultMessage="Get in touch"/>
            </Link>
            
          </p>
        </div>
      </div>
    );
  }
}

export default Homepage;
